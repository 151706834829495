//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Badges
.badge {
  text-transform: uppercase;
}

// Dot Helper
.dot {
  display: inline-block;
  font-size: 1em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: $primary;
  margin-right: .625em;

  &--success {
    background-color: $success;
  }

  &--warning {
    background-color: $warning;
  }

  &--danger {
    background-color: $danger;
  }
}